@charset "utf-8";

// Vendors
@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/bootstrap-v4-dev/dist/css/bootstrap-grid.css";
@import "../../node_modules/swiper/dist/css/swiper.css";
@import "../../node_modules/magnific-popup/dist/magnific-popup.css";

// Imports
@import 'imports/base';
@import 'imports/site-header';
@import 'imports/site-main';
@import 'imports/site-footer';

.holiday {
  width: 100%;
  padding: 75px 15px;
  text-align: center;
  background: radial-gradient(ellipse at center, rgba($c2, 0.7) 0%, rgba($c2, 1) 100%);
  overflow: hidden;

  &-card {
    position: relative;
    width: 100%;
    max-width: 930px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    overflow: hidden;
  }

  img {
    display: inline-block;
    float: right;
    padding-left: 310px;

    @media (max-width: $md) {
      padding-left: 250px;
    }

    @media (max-width: $sm) {
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
      margin-top: 15px;
    }
  }

  &-text {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    max-width: 330px;

    @media (max-width: $md) {
      max-width: 250px;
    }

    @media (max-width: $sm) {
      position: relative;
      top: auto;
      left: auto;
      max-width: 320px;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
      display: block;
    }

    &_main {
      font-family: $ff2;
      font-size: 30px;
      line-height: 1.2;
      text-align: left;
      padding-right: 55px;
      background: url('../img/holiday-bubble.png') bottom right no-repeat;

      @media (max-width: $md) {
        font-size: 22px;
      }

      @media (max-width: $sm) {
        padding: 0;
        background: transparent;
      }

      span {
        display: block;
        padding: 0 50px 5px 0;

        @media (max-width: $md) {
          padding-right: 15px;
        }

        @media (max-width: $sm) {
          border: 1px solid #fff;
          padding: 10px;
        }
      }
    }

    &_sub {
      font-family: $ff1;
      font-size: 18px;
      font-weight: 300;
      font-style: italic;
      padding-top: 5px;
      padding-right: 58px;
      line-height: 1.3;
      text-align: right;

      @media (max-width: $md) {
        font-size: 14px;
      }

      @media (max-width: $sm) {
        text-align: left;
      }
    }
  }
}
