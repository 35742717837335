.site-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: #fff;
  background: $c1;
  box-shadow: 0 0 5px rgba(#000, 0.4);
  z-index: 9;
}

.header-holder {
  width: 100%;
  height: 8.4rem;

  @media (max-width: $xl) {
    height: 7.3rem;
  }

  @media (max-width: $lg) {
    height: 6.3rem;
  }

  @media (max-width: $md) {
    height: 5.2rem;
  }

  @media (max-width: $sm) {
    height: 4rem !important; /* stylelint-disable-line declaration-no-important */
  }
}

.main-navigation {
  padding: 2.5rem 0;

  @media (max-width: $xl) {
    padding: 2rem 0;
  }

  @media (max-width: $lg) {
    padding: 1.5rem 0;
  }

  @media (max-width: $md) {
    padding: 1rem 0;
  }
}

.nav-opener {
  position: relative;
  width: 3rem;
  height: 2rem;
  margin-right: auto;
  margin-left: auto;
  display: block;
  cursor: pointer;

  @media (min-width: $sm + 1) {
    display: none;
  }

  @media (max-width: $sm) {
    display: block;
  }

  span {
    position: absolute;
    width: 3rem;
    height: 2px;
    background: #fff;
    transition: $duration;

    &:nth-child(1) {
      top: 2px;
    }

    &:nth-child(2) {
      top: 9px;
    }

    &:nth-child(3) {
      top: 16px;
    }
  }
}

.is-opened {
  span {
    &:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
}

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  @media (min-width: $sm + 1) {
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }

  @media (max-width: $sm) {
    display: none;
    padding-top: 1.5rem;
  }
}

.menu-item {
  font-family: $ff1;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1;
  display: inline;
  text-transform: uppercase;

  @media (max-width: $lg) {
    font-size: 1.3rem;
  }

  @media (max-width: $md) {
    font-size: 1.2rem;
  }

  @media (max-width: $sm) {
    display: block;
  }
}

.menu-link {
  position: relative;
  padding: 1rem 1.5rem;
  display: inline-block;
  color: #fff;
  text-decoration: none;

  @media (max-width: $md) {
    padding: 1rem;
  }

  &:after {
    position: absolute;
    bottom: 0.3rem;
    right: 1.5rem;
    left: 1.5rem;
    width: 0;
    height: 2px;
    background: #fff;
    transition: $duration;
    content: '';

    @media (max-width: $md) {
      right: 1rem;
      left: 1rem;
    }
  }

  &:hover,
  &:focus,
  &.is-active {
    color: #fff;
    text-decoration: none;

    &:after {
      width: calc(100% - 3rem);

      @media (max-width: $md) {
        width: calc(100% - 2rem);
      }
    }
  }
}
