.site-footer {
  padding: 6rem 0;
  color: #fff;
  background: $c3;
  border-top: 1.5rem solid $c2;

  @media (max-width: $lg) {
    padding: 5rem 0;
  }

  @media (max-width: $md) {
    padding: 4rem 0;
  }

  @media (max-width: $sm) {
    padding: 3rem 0;
  }

  @media (max-width: $xs) {
    padding: 2rem 0;
  }
}

.site-info {
  position: relative;
  font-size: 1.4rem;
  font-weight: 200;
  letter-spacing: 2px;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: $md) {
    font-size: 1.3rem;
  }

  @media (max-width: $sm) {
    font-size: 1.2rem;
  }

  a {
    color: #fff;

    &:hover {
      color: $c1;
      text-decoration: none;
    }
  }
}

.footer-girl {
  position: absolute;
  right: 5%;
  bottom: 5rem;
  width: 30rem;
  height: 25rem;
  display: block;
  background: url('../img/footer-girl.png') no-repeat;
  background-size: 25rem 25rem;

  @media (max-width: $lg) {
    bottom: 4rem;
    width: 22rem;
    height: 22rem;
    background-size: 22rem 22rem;
  }

  @media (max-width: $md) {
    bottom: 3rem;
    width: 18rem;
    height: 18rem;
    background-size: 18rem 18rem;
  }

  @media (max-width: $md) {
    right: 2%;
    bottom: 2rem;
  }

  @media (max-width: $xs) {
    bottom: 1rem;
  }
}

.socials {
  position: absolute;
  right: 5rem;
  bottom: -1.4rem;
  z-index: 9;

  @media (max-width: $lg) {
    right: 3rem;
  }

  @media (max-width: $md) {
    position: relative;
    right: auto;
    bottom: auto;
    text-align: center;
  }
}

.facebook,
.instagram {
  float: left;
  width: 4.8rem;
  height: 4.8rem;
  display: inline-block;
  text-indent: -999rem;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: $md) {
    float: none;
    width: 3.6rem;
    height: 3.6rem;
    background-size: 3.6rem 3.6rem;
  }
}

.facebook {
  margin-left: 0.8rem;
  background-image: url('../img/facebook.svg');

  @media (max-width: $md) {
    margin: 0 0.4rem 1.6rem;
  }
}

.instagram {
  background-image: url('../img/instagram.svg');

  @media (max-width: $md) {
    margin: 0 0.4rem 1.6rem;
  }
}
