.intro {
  &-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 2;

    img {
      padding: 1.5rem 2.5rem 6rem;

      @media (max-width: $md) {
        width: 45rem;
      }

      @media (max-width: $sm) {
        width: 40rem;
      }
    }
  }

  &-desc {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1rem 0;
    color: #fff;
    background: rgba($c3, 0.5);
    z-index: 3;
  }

  &-title {
    font-family: $ff2;
    font-weight: 400;
    font-size: 4.5rem;
    line-height: 1.2;
    text-align: center;
    margin: 0;

    @media (max-width: $md) {
      font-size: 2.7rem;
    }
  }

  &-container {
    width: 100%;
    height: 70%;
    background: $c2;

    .swiper-slide {
      background-color: $c2;
      background-position: center center;
      background-size: cover;
    }
  }
}

.site-content {
  padding-bottom: 22rem;
  background: $c1;

  @media (max-width: $lg) {
    padding-bottom: 20rem;
  }

  @media (max-width: $sm) {
    padding-bottom: 17rem;
  }
}

.content-area {
  padding-top: 5rem;

  @media (max-width: $lg) {
    padding-top: 4rem;
  }

  @media (max-width: $md) {
    padding-top: 3rem;
  }

  @media (max-width: $sm) {
    padding-top: 2rem;
  }

  @media (max-width: $xs) {
    padding-top: 1rem;
  }
}

.page-title {
  float: left;
  position: relative;
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  padding-bottom: 0.5rem;

  @media (max-width: $lg) {
    font-size: 2.7rem;
  }

  @media (max-width: $md) {
    font-size: 2.4rem;
  }

  @media (max-width: $sm) {
    font-size: 2.1rem;
  }

  &:after {
    position: absolute;
    right: 0;
    bottom: -1rem;
    left: 0;
    height: 4px;
    background: url('../img/dot.png') repeat-x;
    content: '';
  }
}

.container-iframe {
  overflow-x: hidden;
  width: 100%;
}

iframe {
  width: 100%;
}

.page-content {
  font-style: italic;

  ul,
  ol {
    li {
      font-family: $ff1;
      font-size: 1em;
      font-style: italic;
      line-height: 1.7;
      color: $c3;

      a {
        color: $text;
      }
    }
  }

  &.termin-iframe {
    @media (min-width: $md) {
      width: 720px;
    }

    @media (max-width: $md) {
      width: 540px;
    }

    @media (max-width: $sm) {
      display: none;
    }

    @media (max-width: $xs) {
      display: none;
    }
  }

  &.termin-link {
    @media (min-width: $sm) {
      display: none;
    }
  }
}

.green {
  padding: 0 5rem;
  background: $c2;

  @media (max-width: $md) {
    padding: 0 3rem;
    text-align: left;
  }

  @media (max-width: $sm) {
    padding: 0 2.3rem;
  }

  @media (max-width: $xs) {
    padding: 0 1.5rem;
  }

  &-title {
    font-weight: 700;
    color: #fff;
    text-align: right;

    @media (max-width: $md) {
      text-align: left;
    }
  }

  em {
    color: $c4;
  }

  &-gradient {
    width: 100%;
    height: 20rem;
    background: linear-gradient(to bottom, $c2 0%, transparent 100%);
  }
}

#map {
  width: 100%;
  height: 400px;

  @media (max-width: $md) {
    height: 350px;
  }

  @media (max-width: $sm) {
    height: 300px;
  }
}

.subpages {
  margin: 0;
  padding: 0;
  list-style: none;

  &-item {
    font-family: $ff2 !important; /* stylelint-disable-line declaration-no-important */
    font-size: 25px !important; /* stylelint-disable-line declaration-no-important */
    line-height: 30px !important; /* stylelint-disable-line declaration-no-important */
    display: block;
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '→';
    }

    &.item-back {
      &:before {
        content: '←';
      }
    }
  }

  &-link {
    padding: 5px 10px 5px 35px;
    display: inline-block;
    text-decoration: none;
  }
}

.gallery {
  margin: 0 -5px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &-item {
    width: 25%;
    flex: 0 0 25%;

    @media (max-width: $md) {
      width: 33.33334%;
      flex: 0 0 33.33334%;
    }

    @media (max-width: $xs) {
      width: 50%;
      flex: 0 0 50%;
    }
  }

  &-link {
    margin: 5px;
    border: 2px solid $c2;
    display: block;
    transition: $duration;

    &:hover {
      transform: scale(1.05, 1.05);
    }

    img {
      display: block;
    }
  }
}
