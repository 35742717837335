$c1:       #f4a7a5;
$c2:       #a5cebc;
$c3:       #060607;
$c4:       #f06;

$text:     #444;
$link:     #060607;
$hover:    #418868;
$focus:    $hover;
$border:   #ddd;

$ff1:      'Avenir W01', sans-serif;
$ff2:      'Lobster', cursive;

$duration: 0.2s;

$xl:       1199px;
$lg:       991px;
$md:       767px;
$sm:       575px;
$xs:       383px;

:root {
  font-size: 10px;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  font-family: $ff1;
  font-size: 1.7em;
  font-weight: 400;
  line-height: 1.625;
  color: $c3;

  @media (max-width: $lg) {
    font-size: 1.65em;
  }

  @media (max-width: $sm) {
    font-size: 1.6em;
  }
}

::selection {
  color: $c3;
  background: $c1;
  text-shadow: none;
}

a {
  color: $link;
  text-decoration: underline;

  &:hover {
    color: $hover;
    text-decoration: underline;
  }

  &:focus {
    color: $focus;
    text-decoration: underline;
  }
}

p {
  font-size: 1em;
  margin: 0 0 1.625em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: calc(1.625 / 1.25);
  margin: 1em 0 calc(1.625em / 4);
}

/* stylelint-disable */
h1 {font-size: 1.6em;}
h2 {font-size: 1.4em;}
h3 {font-size: 1.2em;}
h4 {font-size: 1em;}
h5 {font-size: 0.9em;}
h6 {font-size: 0.8em;}
/* stylelint-enable */

ul,
ol {
  margin: 1em 0 1.625em;
  padding-left: 1.625em;

  ul,
  ol {
    margin: 0;
  }

  li {
    color: $text;
  }
}

code {
  margin: 1em 0 1.625em;
}

pre {
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 1em 0 1.625em;
  padding: 0.5em 1em;
  border: 1px solid $border;
}

blockquote {
  font-size: 1.8rem;
  font-style: italic;
  position: relative;
  margin: 1em 0 1.625em;
  padding: 1em 2.5em;

  &:before {
    position: absolute;
    top: -10px;
    left: 0;
    content: '\201C';
    font-size: 4em;
  }
}

small {
  font-size: 0.85em;
}

figure {
  margin: 1em 0 1.625em;
}

figcaption {
  margin: 1em 0 1.625em;
}

hr {
  margin: 1.625em 0;
  border: 1px solid $text;
  border-width: 1px 0 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.center-block {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

img {
  max-width: 100%;
  height: auto;
}

/* stylelint-disable */
.left     {float: left;}
.right    {float: right;}
.center   {text-align: center;}
.m0       {margin: 0;}
.mt0      {margin-top: 0;}
.mb0      {margin-bottom: 0;}
.p0       {padding: 0;}
.pt0      {padding-top: 0;}
.pb0      {padding-bottom: 0;}
.w100     {width: 100%;}
.w50      {width: 50%;}
.inline   {display: inline;}
.block    {display: block;}
.iblock   {display: inline-block;}
.relative {position: relative;}
.absolute {position: absolute;}
.fixed    {position: fixed;}
.pointer  {cursor: pointer;}
.img-rspv {max-width: 100%; height: auto;}
.mx-auto  {margin-right: auto; margin-left: auto;}
/* stylelint-enable */
